.App {
  text-align: center;
}

.rehab-guide-track-0 {
  background-color: rgb(59 130 246);
}

.listingremarks div div div div:nth-child(2) {border:0; color: white;}
.listingremarks div div div span {background-color: white;}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

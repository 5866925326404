@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Text */
  --st-darkest: #000000; /* black */
  --st-darker: #111827; /* gray-900 */
  --st-dark: #1f2937; /* gray-800 */
  --st-normal: #374151; /* gray-700 */
  --st-light: #4b5563; /* gray-600 */
  --st-lighter: #6b7280; /* gray-500 */
  --st-lightest: #9ca3af; /* gray-400 */
  --st-soft: #d1d5db; /* gray-300 */

  /*   Buttons */
  --sb-primary: #000000; /* in black */
  --sb-primary-hover: #000000;
  --sb-primary-focus: #000000;
  --sb-secondary: #7aa0ba; /* in blue */
  --sb-secondary-hover: #4f7b98;
  --sb-secondary-focus: #1d4ed8;

  /*   Links */
  --sl-dark: #1e40af; /* blue-800 */
  --sl-dark-hover: #1e40af;
  --sl-dark-focus: #1e40af;
  --sl-normal: #1d4ed8; /* blue-700 */
  --sl-normal-hover: #1d4ed8;
  --sl-normal-focus: #1d4ed8;
  --sl-light: #2563eb; /* blue-600 */
  --sl-light-hover: #2563eb;
  --sl-light-focus: #2563eb;
  --sl-lighter: #3b82f6; /* blue-500 */
  --sl-lighter-hover: #3b82f6;
  --sl-lighter-focus: #3b82f6;
  --sl-lightest: #60a5fa; /* blue-400 */
  --sl-lightest-hover: #60a5fa;
  --sl-lightest-focus: #60a5fa;

  /* Colors */
  --success: #dcfce7;
  --warning: #fff2cc;
  --danger: #fee2e2;
  --info: #dbeafe;
  --focus-border: #60a5fa; /* blue-400 */
  --focus-ring: #3b82f6; /* blue-500 */
}

/* Remove --tw-shadow that @tailwindcss/forms
  adds by default and affects react-select */
input[type="text"] {
  --tw-shadow: none;
}

/* Remove up/down arrows from <input type="number"...> */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Styles used in maps */

#browseMapControls {
  display: block;
  white-space: nowrap;
  top: 9px;
}

#browseMapControls .mapControl {
  border-radius: 4px;
  border: 1px solid rgba(185, 192, 163, 1);
  height: 33px;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  padding: 6px 10px;
  padding-top: 8px;
  color: #000;
}

#browseMapControls .mapControl svg {
  display: inline-block;
  margin: -2px -4px 0 2px;
}

#browseMapControls .mapControl.show svg {
  transform: rotate(180deg);
}

#browseMapControls .mapControlItem {
  cursor: pointer;
  width: 60px;
}

#browseMapControls .mapControlItem .mapControlText {
  color: #000;
  font-size: 15px;
  line-height: 29px;
  text-align: center;
}

#browseMapControls .mapControlItem .mapControlText.active {
  color: #000;
  font-weight: 500;
  background-color: #fff;
}

#browseMapControls .mapControlItem .mapControlText:hover {
  background-color: #ddd;
}

#browseMapControls .mapControlLabel span {
  font-weight: lighter;
}

#browseMapControls .mapControl .mapControlLabel {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 29px;
  padding-top: 0px;
  padding-left: 8px;
  padding-right: 8px;
}

#browseMapControls .mapControl:hover {
  background-color: #eee;
}

#browseMapControls .dropDownContainer {
  display: none;
}

#browseMapControls .dropDownContainer.show {
  display: block;
  width: 74px;
  border-radius: 4px;
  float: right;
  border-radius: 4px;
  border: 1px solid rgba(185, 192, 163, 1);
  background-color: #fff;
}

#browseMapLegend {
  position: absolute;
  top: 43px;
  left: 1px;
}

#browseMapLegend.mobile {
  position: absolute;
  top: 169px;
  left: 1px;
  border: 1px;
  border-radius: 8px;
  border-color: rgba(185, 192, 163, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

#browseMapLegend .legendTable {
  background: white;
}

#browseMapLegend .lgColor {
  padding: 6px;
  width: 15px;
  height: 10px;
  opacity: 0.55;
}

#browseMapLegend .lgText {
  background: white;
  padding: 2px;
  font-size: 9px;
  border: 0;
  font-family: sans-serif;
  text-align: center;
}

#browseMapFreeHandControl {
  white-space: nowrap;
}

#browseMapFreeHandControl .mapControl {
  border-radius: 4px;
  border: 1px solid rgba(185, 192, 163, 1);
  background-color: #fff;
  cursor: pointer;
  float: left;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  margin-left: 10px;
}

#browseMapFreeHandControl .mapControl:hover {
  background-color: #eee;
}

#browseMapFreeHandControl .mapControl .mapControlText {
  color: #000;
  padding: 5px 10px;
  padding-top: 7px;
}

#browseMapFreeHandControl .mapControl.hideRemoveAll {
  display: none;
}

#browseMapRemoveAreasControl {
  top: 73px;
  right: 45px;

  /* -webkit-box-shadow: 3px 3px 15px -6px rgba(0, 0, 0, 0.93);
  box-shadow: 3px 3px 15px -6px rgba(0, 0, 0, 0.93); */
  border-radius: 4px;
  border: 1px solid rgba(185, 192, 163, 1);
  height: auto;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
}
#browseMapRemoveAreasControl.mobile {
  display: none;
}

#browseMapRemoveAreasControl .mapControl {
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  color: #000;
  padding: 4px;
  height: 100%;
}

#browseMapRemoveAreasControl .mapControl:hover {
  background-color: #eee;
}

/* Styles used in comps scroll */

.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: #999 #333;
  overflow-y: hidden;
}
.styled-scrollbars::-webkit-scrollbar {
  height: 8px; /* Mostly for horizontal scrollbars */
}
.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #999;
  overflow-y: hidden;
  border-radius: 20px;
}
.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: #f5f5f5;
  overflow-y: hidden;
  border-radius: 20px;
  padding: 40px;
}

.rehab-guide-track-0 {
  background-color: #333;
}

.rehab-guide-track-1 {
  background-color: #999;
}

.rentgraph-tooltip {
  font-size: 10pt;
  padding: 8px;
}

.rentgraph-tooltip-revrow {
  margin: 5px 0;
  text-align: right;
  white-space: nowrap;
}

.rentgraph-tooltip-costrow {
  margin: 5px 0 5px 10px;
  text-align: right;
  white-space: nowrap;
}

.rentgraph-tooltip-pointname {
  font-weight: 700;
  float: left;
}

.rentgraph-tooltip-title {
  font-size: 14px;
  font-weight: 700;
}

.rentgraph-table-amount {
  text-align: right;
  white-space: nowrap;
}

.rentgraph-table-label {
  white-space: nowrap;
}

/* Styles used by LeafLet Maps */
.marker {
  position: relative;
  display: inline-block;
}

.marker .marker-text {
  width: 61px;
  height: 21px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding-top: 1px;
  position: absolute;
  font-family: "Arial";
  font-size: 12px;
  border: solid 1px #fff;
}

.marker .marker-hidden {
  display: none !important;
}

.marker .marker-neutral {
  background-color: #000000;
}

.marker .marker-neg {
  background-color: #ef3b42;
}

.marker .marker-default {
  background-color: #01a599;
}

.marker .marker-selected {
  background-color: #2196f3;
}

.marker .marker-viewed {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.marker .marker-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #fff transparent transparent transparent; /* White border for all except viewed */
}

.marker .marker-text::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  z-index: 1;
}

/* Fill colors for each type */
.marker .marker-neutral::before {
  border-color: #000000 transparent transparent transparent;
}

.marker .marker-neg::before {
  border-color: #ef3b42 transparent transparent transparent;
}

.marker .marker-default::before {
  border-color: #01a599 transparent transparent transparent;
}

.marker .marker-selected::before {
  border-color: #2196f3 transparent transparent transparent;
}

.marker .marker-viewed::before {
  border-color: #ffffff transparent transparent transparent;
}

/* Special case for viewed marker - black border instead of white */
.marker .marker-viewed::after {
  border-color: #000000 transparent transparent transparent;
}

/* Remove all the old ::after rules */

.marker-cluster-icon {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: white;
}

.marker-cluster-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.9;
}

.marker-cluster-icon.marker-cluster-icon-1 {
  line-height: 48px;
}
.marker-cluster-icon.marker-cluster-icon-2 {
  line-height: 51px;
}
.marker-cluster-icon.marker-cluster-icon-3 {
  line-height: 54px;
}
.marker-cluster-icon.marker-cluster-icon-4 {
  line-height: 57px;
}
.marker-cluster-icon.marker-cluster-icon-5 {
  line-height: 60px;
}

.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  will-change: transform;
}

.simple-marker {
  width: 20px;
  height: 35px;
  position: relative;
}

.simple-marker .text {
  position: absolute;
  top: 2.5px;
  left: 0;
  width: 20px;
  text-align: center;
  font-family: "Arial";
  font-size: 12px;
  font-weight: 500;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}
.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
}
.line-clamp-7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  line-clamp: 7;
}
.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  line-clamp: 8;
}

.google-visualization-tooltip {
  top: -20px !important;
}

.google-visualization-tooltip-item {
  white-space: nowrap;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.comp-icon {
  display: relative;
}

.comp-icon span {
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  text-align: center;
}

.comp-icon span.suggested,
spam.included,
span.highlighted {
  color: white;
  font-weight: 600;
}

.comp-icon span.subject {
  color: white;
  font-weight: 800;
  font-size: 20px;
  top: -6px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  opacity: 0.7 !important;
}

.swiper-button-disabled {
  opacity: 0.3 !important;
}

.mapTooltip {
  padding: 1px !important;
  text-align: center;
  line-height: normal;
}

.mapTooltipOutlines {
  padding: 2px !important;
  text-align: center;
  line-height: normal;
  color: white !important;
  background-color: #000 !important;
  border: none !important;
}

.mapTooltipOutlines:before {
  opacity: 0 !important;
}

.leaflet-top.leaflet-right {
  padding-top: 8px;
  padding-right: 0px;
}

.leaflet-bar,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  background-color: #000 !important;
}
